.schedule-container {
  position: relative;
  z-index: 5;
}

.schedule-grid {
  display: flex;
  flex-direction: row;
  min-width: 800px;
  width: 100%;
  /* z-index: 55; */
  background-color: rgba(255, 0, 0, 0);
  /* border: solid 1px green;   */
}

.schedule-timegrid {
  position: absolute;
  top: 0;
  min-width: 800px;
  /* left: 8; */
  width: 100%;
  z-index: -1;
  /* border: solid 1px red; */
}

.schedule-column {
  width: calc(100% / 7);
  min-width: 115px;
  display: flex;
  flex-direction: column;
  border: solid 1px #ccc;
  position: relative;
  background-color: rgba(255, 0, 0, 0);
}

.schedule-item {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 2px;
  position: absolute;
  margin-left: 2px;
  margin-right: 2px;
  padding: 3px;
  font-size: x-small;
  font-weight: bold;
  /* box-shadow: #000 2px 2px 2px; */
  overflow: hidden;
}

.schedule-header {
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
  height: 30px;
  text-align: center;
  justify-content: center;
  display: table;
}

.schedule-headertext {
  /* font-size: 20px; */
  display: table-cell;
  vertical-align: middle;
}

.schedule-hour {
  height: 30px;
  border-bottom: 1px solid #ccc;
}

.schedule-hourhour {
  height: 60px;
  border-bottom: 1px solid #ccc;
}

.schedule-hourtext {
  text-align: center;
  overflow: visible;
  padding-top: 46px;
}

.schedule-hourtexttwo {
  text-align: center;
  overflow: visible;
  padding-top: 16px;
}

.schedule-halfhour {
  height: 30px;
  border-bottom: 1px dotted #ccc;
}

.schedule-timeheader {
  text-align: center;
  /* margin-left: calc(70% / 7); */
  /* background-image: linear-gradient(to bottom, #f1f1f1, #f1f1f1 1px, transparent 1px, transparent 100%);
  background-size: 100% 30px;
  background-repeat: repeat-x; */
  /* background-color: white; */
  padding: 15px;
  background-image: radial-gradient(white 5%, transparent 60%, transparent);
  overflow: visible;
}

.schedule-timeheader-first {
  /* margin-left: calc(70% / 14); */
  /* background-image: linear-gradient(to bottom, #f1f1f1, #f1f1f1 1px, transparent 1px, transparent 100%);
  background-size: 100% 30px;
  background-repeat: repeat-x; */
  padding: 15px;
  background-image: radial-gradient(white 5%, transparent 60%, transparent);
  overflow: visible;
}

.schedule-item-button {
  border: 0px;
  border-radius: 4px;
  font-size: xx-small;
  color: white;
  height: 16px;
}

.stillroom {
  background-color: #3f51b5;
}

.littleroom {
  background-color: #fff019;
  color: black;
}

.noroom {
  background-color: #ff0000;
  color: black;
}

.cancelcolor {
  background-color: #e115a4;
  color: black;
}

.entryended {
  background-color: #868686;
  color: white;
}

.toqueue {
  background-color: #a643c1;
  color: white;
}

.schedule-ingress {
  font-size: x-small;
  font-weight: normal;
}
