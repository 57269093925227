body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centeredbox {
  position: 'fixed';
  top: '50%';
  left: '50%';
  /* bring your own prefixes */
  transform: 'translate(-50%, -50%)';
}

.st0 {
  fill: none;
  /* stroke:#3f51b5; */
  stroke-width: 25;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1{
  fill: none;
  /* stroke: #000000; */
  stroke-width:25;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10;
}